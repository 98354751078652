import { bindable, customElement, inject } from "aurelia-framework";
import { Client } from "../../api/client";
import Sortable from "sortablejs";
import * as _ from "lodash";
import "./tour-guide-assignment.less";
import { BindingSignaler } from "aurelia-templating-resources";
import { I18N } from "aurelia-i18n";
import { FlashService } from "../../flash/flash-service";
import { EventAggregator } from "aurelia-event-aggregator";

@customElement('sio-guide-participant-assignment')
@inject(Client, I18N, FlashService, EventAggregator, BindingSignaler)
export class GuideParticipantAssignment {

    @bindable object;
    participants = [];
    unassignedParticipants = [];
    groupedUnassignedParticipants = [];
    groups = [];
    loading = false;
    sortables = [];
    changesKey = null;
    viewReady = false;

    constructor(client, i18n, flash, ea, signaler) {
        this.client = client;
        this.i18n = i18n;
        this.flash = flash;
        this.ea = ea;
        this.signaler = signaler;
    }

    async attached() {
        this.viewReady = true;
        if (this.object) {
            await this.objectChanged();
        }
    }

    detached() {
        this.setChanged(false);
        this.viewReady = false;
        this.destroySortables();
    }

    async objectChanged() {
        if (!this.object) return;
        this.loading = true;
        await this.fetchAssignments();
        this.loading = false;
    }

    async fetchAssignments() {
        try {
            let assignments = await this.client.get('tour-guide-tourism/guide-participant-assignment/' + this.object.id);

            this.participants = _.uniqBy(assignments.participants || [], 'id');
            this.unassignedParticipants = _.uniqBy(assignments.unassignedParticipants || [], 'id');
            this.groups = this.processGroups(assignments.groups || []);
            this.groupedUnassignedParticipants = this.groupParticipantsByOrder(this.unassignedParticipants);

            console.log('groups is ', this.groups);

            this.refreshView();
            setTimeout(() => this.initializeSortables(), 0);
        } catch (error) {
            console.error('Error fetching assignments:', error);
            this.flash.error('Error fetching assignments.');
        }
    }

    processGroups(groups) {
        return groups.map(group => ({
            ...group,
            id: group?.tourGuide?.id ?? null,
            participants: _.uniqBy(group.participants || [], 'id'),
            groupedParticipants: this.groupParticipantsByOrder(group.participants)
        }));
    }

    groupParticipantsByOrder(participants) {
        const grouped = _.groupBy(participants, 'orderParticipantGroup');
        return Object.keys(grouped).flatMap(key => {
            if (key === 'undefined' || key === '') {
                return Object.keys(participants).map(groupKey => ({
                    orderParticipantGroup: groupKey,
                    participants: participants[groupKey]
                }));
            } else {
                return [{
                    orderParticipantGroup: key,
                    participants: grouped[key]
                }];
            }
        });
    }

    initializeSortables() {
        this.destroySortables();

        const containers = document.querySelectorAll('.assignments');
        containers.forEach(container => {
            const sortable = new Sortable(container, {
                group: 'shared',
                animation: 150,
                draggable: '.assignment-card',
                onEnd: (evt) => this.handleMove(evt)
            });
            this.sortables.push(sortable);
        });
    }

    destroySortables() {
        this.sortables.forEach(sortable => sortable.destroy());
        this.sortables = [];
    }

    handleMove(evt) {
        const groupId = evt.item.getAttribute('data-group-id');
        if (!groupId) {
            console.error('Group ID is missing from the dataset.');
            return;
        }

        const fromId = evt.from.id;
        const toId = evt.to.id;
        console.log('Moving group:', groupId, 'from', fromId, 'to', toId);
        this.moveGroupBetweenContainers(groupId, fromId, toId);
        this.refreshView();
        this.setChanged(true);
    }

    moveGroupBetweenContainers(groupId, fromId, toId) {
        let sourceGroup, targetGroup;
        let participantsToMove = [];

        if (fromId === 'unassigned') {
            sourceGroup = this.groupedUnassignedParticipants;
            const groupToMove = sourceGroup.find(g => g.orderParticipantGroup === groupId);
            participantsToMove = groupToMove?.participants || [];
            this.groupedUnassignedParticipants = sourceGroup.filter(g => g.orderParticipantGroup !== groupId);
            this.unassignedParticipants = this.unassignedParticipants.filter(p => !participantsToMove.some(mp => mp.id === p.id));
        } else {
            sourceGroup = this.groups.find(g => g.id == fromId);
            if (sourceGroup) {
                const groupToMove = sourceGroup.groupedParticipants.find(g => g.orderParticipantGroup === groupId);
                participantsToMove = groupToMove?.participants || [];
                sourceGroup.groupedParticipants = sourceGroup.groupedParticipants.filter(g => g.orderParticipantGroup !== groupId);
                sourceGroup.participants = sourceGroup.participants.filter(p => !participantsToMove.some(mp => mp.id === p.id));
            }
        }

        if (toId === 'unassigned') {
            this.groupedUnassignedParticipants.push({ orderParticipantGroup: groupId, participants: participantsToMove });
            this.unassignedParticipants = [...this.unassignedParticipants, ...participantsToMove];
        } else {
            targetGroup = this.groups.find(g => g.id == toId);
            if (targetGroup) {
                const existingGroup = targetGroup.groupedParticipants.find(g => g.orderParticipantGroup === groupId);
                if (existingGroup) {
                    existingGroup.participants = [...existingGroup.participants, ...participantsToMove];
                } else {
                    targetGroup.groupedParticipants.push({ orderParticipantGroup: groupId, participants: participantsToMove });
                }
                targetGroup.participants = [...targetGroup.participants, ...participantsToMove];
            }
        }

        // Update the participants for all groups
        this.groups.forEach(group => {
            group.participants = group.groupedParticipants.flatMap(g => g.participants);
        });

        this.refreshView();
        this.setChanged(true);
    }

    refreshView() {
        this.groups = [...this.groups];
        this.unassignedParticipants = [...this.unassignedParticipants];
        this.groupedUnassignedParticipants = [...this.groupedUnassignedParticipants];
        this.signaler.signal('tour-guide-fetched-assignments');
    }

    async save() {
        try {

            if (!this.changesKey) {
                this.flash.flash("warning", this.i18n.tr("tour-guide-tourism.no-changes"));
                return;
            }

            let updateAssignments = this.groups.map(group => ({
                tourGuide: group.tourGuide,
                participants: group.participants.map(participant => ({ id: participant.id }))
            }));

            if (this.unassignedParticipants.length > 0) {
                updateAssignments.push({
                    tourGuide: null,
                    participants: this.unassignedParticipants.map(participant => ({ id: participant.id }))
                });
            }

            let data = await this.client.put('tour-guide-tourism/guide-participant-assignment/assignments/' + this.object.id, { groups: updateAssignments });
            this.flash.success('form.success');
            this.setChanged(false);
            return data;
        } catch (error) {
            console.error('Error saving assignments:', error);
            this.flash.error('Error saving assignments.');
        }
    }

    setChanged(changed) {
        if (changed) {
            if (this.changesKey != null) return;
            this.changesKey = 'tourism-guide-map/participant__' + new Date();
            this.ea.publish('sio_register_unsaved_changes', { changesKey: this.changesKey });
        } else {
            this.ea.publish('sio_unregister_unsaved_changes', { changesKey: this.changesKey });
            this.changesKey = null;
        }
    }
}
